import React, {Component} from 'react';
import {Link} from 'gatsby-plugin-intl';
import Sticky from 'react-stickynode';
import 'malihu-custom-scrollbar-plugin/';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import 'jquery-mousewheel';
import scrollTo from 'gatsby-plugin-smoothscroll';

class CustomNavbar extends Component {

  state = {
    collapsed: true,
  }

  toggleMenu = (event) => {
    event.preventDefault();
    this.setState({collapsed: !this.state.collapsed});
  }

  closeMenu = () => {
    this.setState({collapsed: true});
  }

  componentDidMount() {
  }

  render() {
    let {mClass, nClass, cClass, slogo} = this.props;
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>

              <Link className={`navbar-brand ${slogo}`} to={"/"}>
                <img src={require("../img/buildnext-rgb-neg-600dpi.png")} alt="buildnext GmbH Logo (dunkler Hintergrund)" className="logo-neg"/>
                <img src={require("../img/buildnext-rgb-600dpi.png")} alt="buildnext GmbH Logo (heller Hintergrund)" className="logo"/>
              </Link>
              <button onClick={this.toggleMenu} className={`navbar-toggler ${this.state.collapsed ? 'collapsed' : ''}`}
                      type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span/>
                                    <span/>
                                    <span/>
                                </span>
                                <span className="hamburger-cross">
                                    <span/>
                                    <span/>
                                </span>
                            </span>
              </button>

              {!this.props.fullRedirect && (
                <div className={`${this.state.collapsed ? 'collapse' : ''} navbar-collapse`}
                     id="navbarSupportedContent">
                  <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                    <li className="nav-item">
                      <a title="Service" className="nav-link" onClick={() => {
                        this.closeMenu();
                        scrollTo('#service_area')
                      }}>Leistungen</a>
                    </li>
                    <li className="nav-item"><a title="Know How" className="nav-link" onClick={() => {
                      this.closeMenu();
                      scrollTo('#know_how_area')
                    }}>Know How</a></li>
                    <li className="nav-item"><a title="Raisenext" className="nav-link" href="https://www.raisenext.de/">Raisenext</a></li>
                    <li className="dropdown submenu nav-item">
                      <a title="Kontakt" className="nav-link" aria-expanded="false"
                            onClick={() => {
                              this.closeMenu();
                              scrollTo('#contact_area')
                            }}>Kontakt</a>
                    </li>

                  </ul>
                </div>
              )}
              {this.props.fullRedirect && (
                <div className={`${this.state.collapsed ? 'collapse' : ''} navbar-collapse`}
                     id="navbarSupportedContent">
                  <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                    <li className="nav-item">
                      <Link to={"/#service_area"} title="Service" className="nav-link">Leistungen</Link>
                    </li>

                    <li className="nav-item"><Link to={"/de/#know_how_area"} title="Know How" className="nav-link">Know
                      How</Link></li>
                    <li className="nav-item"><a title="Raisenext" className="nav-link" href="https://www.raisenext.de/">Raisenext</a></li>
                    <li className="dropdown submenu nav-item">
                      <Link to={"/de/#contact_area"} title="Kontakt" className="dropdown-toggle nav-link" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false">Kontakt</Link>
                    </li>

                  </ul>
                </div>
              )}


            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default CustomNavbar;
