import React, { Component } from 'react';
import { Link, FormattedMessage } from "gatsby-plugin-intl"

class Footer extends Component {
  render() {
    let FooterData = this.props.FooterData;
    return (
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">

          {this.props.showAddress && (
            <div className="container" style={{textAlign: 'center'}}>
              <div className="row align-items-center">
                <div className="col-lg-12 col-sm-12">
                  <p className="mb-0 f_400">
                    <img src={require("../../img/buildnext-rgb-600dpi.png")} alt="logo"
                         style={{maxWidth: '200px'}}/><br/><br/>
                    buildnext GmbH <br/>
                    Bremer Heerstr. 117<br/>
                    26135 Oldenburg<br/>
                    Deutschland<br/>
                    <br/>
                    E-Mail: info@buildnext.io
                    <br/><br/>

                  </p>
                </div>

              </div>
            </div>
          )}
          <div className="footer_bg">
            <div className="footer_bg_one"/>
            <div className="footer_bg_two"/>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-7">
                <p className="mb-0 f_400">{FooterData.copywrite}</p>
              </div>
              <div className="col-lg-6 col-sm-5 text-right">
                <p><Link to="/privacy"><FormattedMessage id="privacy" /></Link> | <Link to="/imprint"><FormattedMessage id="imprint" /></Link></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
